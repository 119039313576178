<template>
<div class="page-privacy-wrap">
    <div class="page-privacy">
        <h1>就职在线用户注册协议</h1>
        <p>欢迎访问就职在线！就职在线网站及相应的手机应用软件由安徽省中网云联科技有限公司负责运营，专注于创新型学习考试资源服务，致力于打造实用的全方位学习考试资源服务平台。请用户在访问和使用就职在线提供的服务前请您仔细阅读本注册协议，在您点击“立即注册”按钮后，本协议即构成对双方有约束力的法律文件。</p>
        <h2>一、用户说明</h2>
        <p>使用就职在线服务协议，该协议包括用户端，以下单独称为用户。</p>
        <h2>二、服务模式</h2>
        <p>用户应了解并知悉，就职在线是为用户提供课程、考试、资源的一家教育综合教考资源服务平台。</p>
        <h2>三、使用细节</h2>
        <p>（一）账户注册</p>
        <p>用户使用就职在线平台的服务，需要注册一个就职在线账户。账户名即为用户所提供的常用手机号码、邮箱，用户设置密码并确认，通过就职在线下发的验证码进行账户验证后，该账户即完成初步注册。用户在用户中心设置用户相关信息。</p>
        <p>（二）账户管理</p>
        <p>用户使用就职在线平台，应负责维护自己账户的保密性，用户对其账户和密码，发生的所有变动承担法律责任。</p>
        <p>（三）订单及支付</p>
        <p>本条款所述“订单”，是指用户在就职在线平台上根据自身需求自行匹配达成的机构、用户之间的教学服务。</p>
        <p>1、用户通过就职在线联系到能够提供服务的机构并商定授课时间、方式等服务信息；</p>
        <p>2、用户课程服务费用直接向机构支付；用户一旦选定课程服务并开通账户，不支持七天无理由退款。</p>
        <p>（四）课后评价体系</p>
        <p>讲师或机构履行完毕与用户达成的教学服务后，用户可在就职在线网站上对讲师或机构的教学服务做出相应的评价。用户应保证评价的真实性和客观性，对不真实不客观或侵犯讲师或机构合法权益的评价，就职在线平台有权依法删除。用户可以在就职在线平台发表对讲师的评论、意见和其他内容，以及向平台提出建议、意见或其他信息，但是该等内容不得违反中国现行法律法规及其他规范性文件的规定，不得含有非法、淫秽、威胁、侮辱、诽谤、侵犯隐私、侵犯知识产权的内容或以其他形式对第三方权利构成侵犯。就职在线在接到有关权利人的投诉与举报后将采取合理的删除或屏蔽等措施。</p>
        <h2>四、双方权利义务及法律责任</h2>
        <p>（一）就职在线享有对本协议约定服务的监督、提示、检查、纠正等权利。</p>
        <p>（二）就职在线有权保留用户注册及使用时预留的所有信息。</p>
        <p>（三）就职在线有权删除或屏蔽用户上传的非法及侵权信息。</p>
        <p>（四）就职在线谨慎使用用户的所有信息，非依法律规定及用户许可，不得向任何第三方透露用户信息</p>
        <p>（五）就职在线不对任何其他第三方搜索引擎的合法信息抓取功能采取屏蔽措施。</p>
        <p>（六）就职在线为任何涉嫌侵权的权利人设置投诉与举报渠道。</p>
        <p>（七）用户须为合法目的使用就职在线提供的教育资源交易平台，用户不得利用就职在线平台发布任何与教育服务无关的商业信息。</p>
        <p>（八）用户对其发布的信息的真实性、合法性、有效性及完整性承担法律责任。用户不得在就职在线平台上发布任何虚假、违法信息及言论。用户上传就职在线的任何内容如涉嫌侵犯第三方合法权利的，用户须独立承担因此所产生法律责任。</p>
        <p>（九）用户与机构约课订单一旦达成，适当履行授课义务并独立承担法律责任。</p>
        <p>（十）用户违反本协议约定情节严重的，就职在线有权关闭用户账户。</p>
        <h2>五、用户注册就职在线平台特别提示用户上传就职在线平台的信息不得含有以下内容：</h2>
        <p>（一）反对宪法基本原则的；</p>
        <p>（二）危害国家统一、主权和领土完整的；</p>
        <p>（三）泄露国家机密、危害国家安全或者损害国家荣誉和利益的；</p>
        <p>（四）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；</p>
        <p>（五）宣扬邪教、迷信的；</p>
        <p>（六）扰乱社会秩序，破坏社会稳定的；</p>
        <p>（七）诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；</p>
        <p>（八）侮辱或者诽谤他人，侵害公民用户隐私等他人合法权益的；</p>
        <p>（九）危害社会公德，损害民族优秀文化传统的；</p>
        <p>（十）有关法律、行政法规和国家规定禁止的其他内容。</p>
        <h2>六、未成年人特别注意事项 </h2>
        <p>如果用户不是具备完全民事权利能力和完全民事行为能力的人，请用户征得其监护人许可或由其监护人直接使用就职在线平台服务。后续上课、付款等事宜也需由监护人陪同或征得监护人许可完成。</p>
        <h2>七、知识产权说明</h2>
        <p>用户需自行下载就职在线提供的手机或电脑软件并安装后，从客户端使用就职在线获得所提供的服务。就职在线在此授予用户不可转让的、允许用户使用由就职在线提供的包含在服务中的软件。但是用户不得复制、修改、发布、出售或出租就职在线的服务软件或所含软件的任何部分。</p>
        <h2>八、免责条款</h2>
        <p>如发生以下情况，就职在线平台不对用户直接或间接损失承担法律责任：</p>
        <p>（一）就职在线系资源交易平台，不保证该等信息的准确性、有效性、及时性或完整性。提供信息的真实性、合法性、有效性及完整性等由信息提供者承担相关法律责任；</p>
        <p>（二）不可抗力事件导致的服务中断或就职在线无法控制的原因所导致的用户损失；</p>
        <p>（三）用户使用就职在线网站（包括链接到第三方网站或自第三方网站链接）而可能产生的计算机病毒、黑客入侵、系统失灵或功能紊乱等导致的用户损失；</p>
        <p>（四）用户将注册账户信息告知他人或与他人共享注册帐号，由此导致的任何风险或损失；</p>
        <p>（五）用户的电脑软件、系统、硬件和通信线路出现故障或自身操作不当；</p>
        <p>（六）由于网络信号不稳定等原因所引起的登录失败、资料同步不完整、页面打开速度慢等；</p>
        <p>（七）用户发布的内容被他人转发、复制等传播可能带来的风险和责任；</p>
        <p>（八）如因系统维护或升级而需要暂停网络服务，就职在线将尽可能事先在就职在线网站进行通知。</p>
        <h2>九、服务终止情形</h2>
        <p>用户在使用就职在线平台服务的过程中，具有下列情形时，就职在线有权终止对该用户的服务：</p>
        <p>（一）用户以非法目的使用就职在线服务；</p>
        <p>（二）用户不以约课上课的真实交易为目的使用就职在线服务；</p>
        <p>（三）用户存在多次被投诉等不良记录的；</p>
        <p>（四）用户侵犯就职在线合法权益的行为。</p>
        <h2>十、隐私声明</h2>
        <p>（一）用户信息的范围包括：用户注册的账户信息、用户上传的信息、就职在线通过合法方式收集的用户信息等；</p>
        <p>（二）用户信息的收集、使用和披露</p>
        <p>为了给用户提供更优质的服务，就职在线保留收集用户信息等权利，就职在线不会向第三方披露任何可能用以识别用户用户身份的信息； 只在如下情况下，就职在线会合法披露用户信息：</p>
        <p>1、经用户事先同意，向第三方披露；</p>
        <p>2、根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
        <p>3、其它根据法律、法规或者政策应进行的披露。</p>
        <p>（三）用户信息的保护</p>
        <p>1、用户的账户均有安全保护功能，请妥善保管账户及密码信息。尽管有安全措施，但同时也请用户注意在信息网络上不存在“绝对的安全措施”；</p>
        <p>2、除非经过用户同意，就职在线不允许任何用户、第三方通过就职在线收集、出售或者传播用户信息；</p>
        <p>3、就职在线平台含有到其他网站的链接，就职在线不对那些网站的隐私保护措施负责。当用户登陆那些网站时，请提高警惕，保护用户隐私。</p>
        <h2>十一、法律适用与争议解决</h2>
        <p>（一）本协议的履行与解释均适用中华人民共和国法律。</p>
        <p>（二）就职在线与用户之间应以友好协商方式解决协议履行过程中产生的争议与纠纷，协商无效时，应提交当地法院通过诉讼解决。</p>
        <h2>十二、本协议条款的修改权与可分性</h2>
        <p>为更好地提供服务并符合相关监管政策，就职在线有权在必要时单方修改或变更本服务协议之内容，并将通过就职在线网站公布最新的服务协议，无需另行单独通知。</p>
        <h2>十四、协议生效</h2>
        <p>（一）本协议自用户注册就职在线之日起生效；</p>
        <p>（二）就职在线在法律许可范围内对本协议拥有解释权。</p>
        <h2>十五、本协议从用户点击同意开始，即时生效。</h2>
    </div>
</div>
</template>
<script>
export default {
    name: 'registerProtocol',
}
</script>
<style lang="stylus" scoped>
.page-privacy-wrap
    background  #fff
    padding-bottom 60px
    .page-privacy
        width 1200px
        margin 0 auto
        h1
            font-size 28px
            line-height 40px
            margin-bottom 62px
            padding-top 84px
            text-align center
        h2
            font-weight:normal;
            line-height 36px
            text-indent :40px;
            margin-top:18px;
            margin-bottom:12px;
        p
            line-height 36px
            font-size 20px
            color #000
            text-indent :40px;
</style>